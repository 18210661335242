import _ from 'lodash';

export const revenueTypes = {
  pickEcom: 'Ecom - Pick (w/ LTM)',
  packEcom: 'Ecom - Pack (w/ LTM)',
  wholesale: 'Wholesale',
  returns: 'Returns',
  receiving: 'Receiving',
  ltmLaser: 'LTM Laser',
  ltmEmbroidery: 'LTM Embroidery'
};

export const revenueTypesList = _.values(revenueTypes);

export const additionalActivities = {
  replenEcom: 'Replenishment (Ecom)',
  inventoryEcom: 'Inventory (Ecom)',
  bulkshipEcom: 'Bulkship (Ecom)',
  indirectEcom: 'Indirect (Ecom)',
  productionRec: 'Production (Receiving)',
  paRec: 'P/A (Receiving)',
  indirectRec: 'Indirect (Receiving)',
  paReturns: 'P/A (Returns)',
  productionRet: 'Production (Returns)',
  indirectRet: 'Indirect (Returns)',
  productionLtm: 'Production (LTM)',
  inventoryLtm: 'Inventory (LTM)',
  onboarding: 'Set Up',
  transportation: 'Transportation',
  logistics: 'Logistics'
};
export const additionalActivitiesList = _.values(additionalActivities);

export const costSubcategoryToRevenueMapping = {
  'Ecom - Pack': revenueTypes.packEcom,
  'Ecom - Pick': revenueTypes.pickEcom,
  'LTM - Emb': revenueTypes.ltmEmbroidery,
  'LTM - Las': revenueTypes.ltmLaser,
  'Production': revenueTypes.production,
  'Receiving': revenueTypes.receiving,
  'Wholesale': revenueTypes.wholesale,
  'Returns': revenueTypes.returns,
  'Replenishment (Ecom)': additionalActivities.replenEcom,
  'Inventory (Ecom)': additionalActivities.inventoryEcom,
  'Bulkship (Ecom)': additionalActivities.bulkshipEcom,
  'Indirect (Ecom)': additionalActivities.indirectEcom,
  'Production (Receiving)': additionalActivities.productionRec,
  'P/A (Receiving)': additionalActivities.paRec,
  'Indirect (Receiving)': additionalActivities.indirectRec,
  'P/A (Returns)': additionalActivities.paReturns,
  'Production (Returns)': additionalActivities.productionRet,
  'Indirect (Returns)': additionalActivities.indirectRet,
  'Production (LTM)': additionalActivities.productionLtm,
  'Inventory (LTM)': additionalActivities.inventoryLtm,
  'Set Up': additionalActivities.onboarding,
  'Transportation': additionalActivities.transportation,
  'Logistics': additionalActivities.logistics
};

export const revenueTypeToForecastMapping = {
  [revenueTypes.pickEcom]: ['ecomUnits'],
  [revenueTypes.packEcom]: ['ecomUnits'],
  [revenueTypes.wholesale]: ['whUnits'],
  [revenueTypes.returns]: ['returnsUnits'],
  [revenueTypes.receiving]: [ 'receivingCartUnits', 'receivingPalUnits', 'receivingUnUnits' ],
  [revenueTypes.ltmLaser]: ['laserUnits'],
  [revenueTypes.ltmEmbroidery]: ['embUnits'],
  // [revenueTypes.production]: 'ecomUnits', // TODO: uncomment this string after production forecast implemented
};

export const planStatuses = {
  'NOT_SUBMITTED': 'NOT_SUBMITTED',
  'SUBMITTED': 'SUBMITTED',
  'RESUBMITTED': 'RESUBMITTED',
  'APPROVED': 'APPROVED',
  'REJECTED': 'REJECTED',
  'RESUBMISSION_ALLOWED': 'RESUBMISSION_ALLOWED'
};

export const assignLocations = {
  '115': 'INTERNAL Ops 115',
  '350+99': 'INTERNAL Ops 350+99'
};

export const allowResubmissionDaysBeforeWeekStart = 1; // Till the end of Friday

export const resubmitDaysBeforeWeekStart = 1; // Till the end of Friday

export const submitDaysBeforeWeekStart = 1; // Till the end of Friday

export const dayBeforeNewWeekSelect = 6; // Till the end of Friday

export const averageComparisonBands = [
  { range: [ -Infinity, -30 ], class: 'under-low' },  // Green
  { range: [ -30, -10 ], class: 'under' },            // Light green
  { range: [ -10, 10 ], class: 'neutral' },           // Grey
  { range: [ 10, 30 ], class: 'over' },               // Orange
  { range: [ 30, Infinity ], class: 'over-high' }     // Red
];
